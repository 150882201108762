import React, { useEffect, useState } from 'react';

import {
    CheckOutlined,
    UndoOutlined
} from "@ant-design/icons";
import { Button, ColorPicker, Input, Select } from 'antd';
import { Color } from 'antd/es/color-picker';
import { useTranslation } from 'react-i18next';
import guideImage from '../../assets/vto/guide.jpg';
import FashionService from '../../services/fashion.service';
import { Titles } from '../../utils/interface';
import NotifyController from '../../utils/toast';
import { UploadFileToS3 } from '../../utils/uploadS3';
import { handleResponseError } from '../../utils/responseError';
const logoDefault = 'https://media2.vision2art.ai/modeli_onlylogo.svg'
function ConfigThemes() {
    const [logoBrand, setLogoBrand] = useState<any>(logoDefault)
    const [logoBrandPreview, setLogoBrandPreview] = useState<any>(logoDefault)
    const [titleHeader, setTitleHeader] = useState<Titles>({
        titleHeaderVN: 'Thử trang phục cùng Modeli',
        titleHeaderEN: 'Try Outfits with Modeli'
    })
    const [themeData, setThemeData] = useState<any>({
        mainColor: 'rgb(0, 71, 255)',
        bgColor: 'rgb(255, 255, 255)'
    })

    const [fontSite, setFontSite] = useState('Inter')
    const { t } = useTranslation()
    const onChange = (value: string) => {
        setFontSite(value)
    };
    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            setLogoBrand(file)
            reader.onloadend = () => {
                const base64String = reader.result;
                setLogoBrandPreview(base64String);
            };
            reader.readAsDataURL(file);
        } else {
            setLogoBrand(null)
            setLogoBrandPreview(null);
        }
    };
    const dataFonts = [
        {
            value: 'Arial', label: <div style={{ fontFamily: 'Arial' }}>Arial</div>
        },
        {
            value: 'Inter', label: <div style={{ fontFamily: 'Inter' }}>Inter</div>
        },
        {
            value: 'Verdana', label: <div style={{ fontFamily: 'Verdana' }}>Verdana</div>
        },
        {
            value: 'Tahoma', label: <div style={{ fontFamily: 'Tahoma' }}>Tahoma</div>
        },
        {
            value: 'Times New Roman', label: <div style={{ fontFamily: 'Times New Roman' }}>Times New Roman</div>
        },
        {
            value: 'Courier New', label: <div style={{ fontFamily: 'Courier New' }}>Courier New</div>
        },
        {
            value: 'Palatino Linotype', label: <div style={{ fontFamily: 'Palatino Linotype' }}>Palatino Linotype</div>
        }
    ];

    const rgbString = React.useMemo(
        () => (typeof themeData.mainColor === 'string' ? themeData.mainColor : themeData.mainColor?.toRgbString()),
        [themeData.mainColor],
    );
    const rgbStringBg = React.useMemo(
        () => (typeof themeData.bgColor === 'string' ? themeData.bgColor : themeData.bgColor?.toRgbString()),
        [themeData.bgColor],
    );
    const onChangeTitleVN = (e: any) => {
        const val = e.target.value;
        setTitleHeader((prevParams: any) => ({
            ...prevParams,
            titleHeaderVN: val,
        }))
    }
    const onChangeTitleEN = (e: any) => {
        const val = e.target.value;
        setTitleHeader((prevParams: any) => ({
            ...prevParams,
            titleHeaderEN: val,
        }))
    }
    const onLoadConfig = async () => {
        try {
            const { data } = await FashionService.getInstance().getUIConfig();
            if (data) {
                setFontSite(data.fontSite);
                setLogoBrandPreview(data.urlLogo)
                setLogoBrand(data.urlLogo);
                setTitleHeader({
                    titleHeaderEN: data?.titleHeader?.titleHeaderEN,
                    titleHeaderVN: data?.titleHeader?.titleHeaderVN,
                })
                setThemeData({
                    mainColor: data.primaryColor,
                    bgColor: data.backgroundColor
                })
            }
        } catch (error) {
            handleResponseError(error);
        }
    }

    const onSave = async () => {
        if (logoBrand) {
            if (typeof logoBrand === 'string' && logoBrand?.startsWith("https://media")) {
                finalSave(logoBrand)
            } else {
                const urlLogo = await UploadFileToS3(logoBrand, 'logo_vto_site.png', 'png')
                setLogoBrandPreview(urlLogo)
                setLogoBrand(urlLogo)
                finalSave(urlLogo)
            }
        } else {
            setLogoBrandPreview('')
            setLogoBrand('')
            finalSave('empty')
        }

    }
    const finalSave = async (urlLogo: string) => {
        try {
            const dataSaveData = await FashionService.getInstance().saveUIConfig(urlLogo, fontSite, titleHeader, rgbString, rgbStringBg);
            if (dataSaveData.success) {
                NotifyController.success(t('apply_success'))
                onLoadConfig()
            } else {
                NotifyController.error(t('something_wrong'))
            }
        } catch (error) {
            handleResponseError(error, t('something_wrong'))
        }
    }
    const onReset = () => {
        setFontSite("Inter");
        setLogoBrandPreview(logoDefault)
        setLogoBrand(logoDefault);
        setTitleHeader({
            titleHeaderVN: 'Thử trang phục cùng Modeli',
            titleHeaderEN: 'Try Outfits with Modeli'
        })
        setThemeData({
            mainColor: 'rgb(0, 71, 255)',
            bgColor: 'rgb(255, 255, 255)'
        })
    }
    useEffect(() => {
        onLoadConfig()
    }, [])
    return (
        <div className='flex flex-col gap-8 ml-10 '>
            <div className='flex  gap-4 w-full flex-wrap'>
                <div>
                    <div className='font-semibold text-lg w-full text-left'>{t('logo_brand')}</div>
                    <div className='flex flex-col gap-2 ml-3 text-left'>
                        <div>
                            {logoBrandPreview != 'empty' && <img src={logoBrandPreview} alt="" width={'100px'} height={'100%'} style={{
                                objectFit: 'contain'
                            }} />}
                        </div>
                        <div>
                            <input type='file' accept='image/png' onChange={handleFileChange}></input>
                            <p className='mt-2 text-[12px] max-w-[200px] text-gray'>{t('support_png_40x40')}</p>
                        </div>
                        {(logoBrandPreview || logoBrandPreview === 'empty') && <Button danger onClick={() => {
                            setLogoBrand(null)
                            setLogoBrandPreview(null);
                        }}>{t('delete')}</Button>}
                    </div>
                </div>
                <div className='w-1/4 sm:w-full'>
                    <div className='font-semibold text-lg w-full text-left'>{t('title_header')}</div>
                    <div className='w-full my-4'>
                        <div className='flex flex-col gap-2 text-left'>
                            <label htmlFor="vn-text" className='font-semibold'>{t('vietnamese')}:</label>
                            <Input className='w-full' id="vn-text" placeholder='Tiêu đề cho tiếng Việt' defaultValue={titleHeader.titleHeaderVN} value={titleHeader.titleHeaderVN} onChange={onChangeTitleVN} allowClear />
                        </div>
                        <div className='flex flex-col gap-2 text-left mt-4'>
                            <label htmlFor="vn-text" className='font-semibold'>{t('english')}:</label>
                            <Input className='w-full' id="vn-text" placeholder='Title for English' defaultValue={titleHeader.titleHeaderEN} value={titleHeader.titleHeaderEN} onChange={onChangeTitleEN} allowClear />
                        </div>
                    </div>
                </div>
                <div className='w-1/4'>
                    <img src={guideImage} alt="" />
                </div>
            </div>
            <div className='flex flex-col gap-2 w-full'>
                <div className='font-semibold text-lg w-full text-left'>{t('font_text')}</div>
                <div className='flex flex-col gap-2 text-left'>
                    <div>
                        <Select
                            defaultValue={fontSite}
                            value={fontSite}
                            placeholder={t("Select a font")}
                            optionFilterProp="label"
                            onChange={onChange}
                            options={dataFonts}
                            style={{ minWidth: '220px' }}
                        />
                    </div>
                </div>
            </div>
            <div className='flex gap-4 w-full'>
                <div className='min-w-[265px]'>
                    <div className='font-semibold text-lg w-full text-left'>{t('bg_color')}</div>
                    <div className='flex flex-col gap-2 ml-3 text-left mt-2'>
                        <div className='w-[200px]'>
                            <ColorPicker
                                value={themeData.bgColor}
                                onChange={(value: Color, hex: string) => {
                                    setThemeData((prevParams: any) => ({
                                        ...prevParams,
                                        bgColor: value,
                                    }))
                                }}
                            />
                            <p className='my-2'>RGB: {rgbStringBg}</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='font-semibold text-lg w-full text-left'>{t('primary_color')}</div>
                    <div className='flex flex-col gap-2 ml-3 text-left mt-2'>
                        <div className='w-[200px]'>
                            <ColorPicker
                                value={themeData.mainColor}
                                onChange={(value: Color, hex: string) => {
                                    setThemeData((prevParams: any) => ({
                                        ...prevParams,
                                        mainColor: value,
                                    }))
                                }}
                            />
                            <p className='my-2'>RGB: {rgbString}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='text-left flex gap-2 w-full mt-4'>
                <div className='font-semibold text-lg w-full text-left flex gap-2'>
                    <Button icon={<UndoOutlined />} type='dashed' onClick={onReset} danger>{t('reset')}</Button>
                    <Button icon={<CheckOutlined />} type='primary' onClick={onSave}>{t('save')}</Button>
                </div>
            </div>
        </div>
    )
}

export default ConfigThemes