import { fabric } from "fabric";
import Resizer from "react-image-file-resizer";
import heic2any from "heic2any";
const MAX_SIZE_WIDTH = 768;
const MAX_SIZE_HEIGHT = 1024;
export function generateRandomNumberString(length: number) {
  let result = "";
  const characters = "0123456789";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
}

export function getImageSize(
  url: string
): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const img = new window.Image();
    img.onload = () => {
      const width = img.width;
      const height = img.height;
      resolve({ width, height });
    };

    img.onerror = () => {
      reject(new Error("Failed to load image."));
    };

    img.src = url;
  });
}

export const convertHeicToJpg = (
  blob: Blob
): Promise<{ resultBlob: string }> => {
  return new Promise((resolve, reject) => {
    heic2any({
      blob: blob,
      quality: 1,
      toType: "image/jpg",
    }).then((resultBlob: any) => {
      resolve({ resultBlob });
    });
  });
};

export const formatDate = (date: number) => {
  return new Intl.DateTimeFormat("vi-VN", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
  }).format(date);
};

export async function urlToBase64(url: string): Promise<string> {
  // Check if the provided 'url' is a valid URL
  if (!isValidURL(url)) {
    return url; // Return the input URL if it's not a valid URL
  }
  try {
    if (!url.includes("?not-from-cache-please")) {
      url += "?not-from-cache-please";
    }
    const response = await fetch(url);
    const blob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    return new Promise<string>((resolve, reject) => {
      reader.onloadend = function () {
        if (reader.result) {
          const base64String = reader.result.toString().split(",")[1];
          resolve(base64String);
        } else {
          reject(new Error("Failed to convert URL to base64."));
        }
      };
    });
  } catch (error) {
    throw new Error("Error fetching or converting the image.");
  }
}

export const convertUrlToBase64 = (url: string) => {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      })
      .catch(reject);
  });
};

// Helper function to check if a string is a valid URL using regex
export function isValidURL(str: string): boolean {
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  return urlRegex.test(str);
}
export const isBlob = (str: string) => {
  if (str.startsWith("blob")) {
    return true;
  }
};
export function isUrl(str: string): boolean {
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  return urlRegex.test(str);
}

export function customizeKey(key: string) {
  const splittedKey = key.split(" ");

  if (splittedKey.length === 1) {
    return key.toLowerCase();
  }

  return splittedKey.map((k) => k.toLowerCase()).join("_");
}
export const scaleImg = (inputW: number, inputH: number) => {
  if (inputW >= 3000 || inputH >= 6000)
    return {
      sX: 1 / 5,
      sY: 1 / 5,
      w: inputW / 5,
      h: inputH / 5,
      multiplier: 5,
    };
  if ((inputW >= 512 && inputW <= 768) || (inputH >= 512 && inputH <= 1152))
    return {
      sX: 1 / 2,
      sY: 1 / 2,
      w: inputW / 2 ,
      h: inputH / 2,
      multiplier: 2,
    };
  if (inputW >= 1200 || inputH >= 1153) {
    return {
      sX: 1 / 3,
      sY: 1 / 3,
      w: inputW / 3,
      h: inputH / 3,
      multiplier: 3,
    };
  }
  return {
    sX: 1,
    sY: 1,
    w: inputW,
    h: inputH,
    multiplier: 1,
  };
};
export const scaleImgForSegment = (inputW: number, inputH: number) => {
  if (inputW >= 3000 || inputH >= 6000)
    return {
      sX: 1 / 5,
      sY: 1 / 5,
      w: inputW / 5,
      h: inputH / 5,
      multiplier: 5,
    };
  if ((inputW >= 512 && inputW <= 768) || (inputH >= 512 && inputH <= 1152))
    return {
      sX: 1 / 1.6,
      sY: 1 / 1.6,
      w: inputW / 1.6 ,
      h: inputH / 1.6,
      multiplier: 1.6,
    };
  if (inputW >= 1200 || inputH >= 1153) {
    return {
      sX: 1 / 3,
      sY: 1 / 3,
      w: inputW / 3,
      h: inputH / 3,
      multiplier: 3,
    };
  }
  return {
    sX: 1,
    sY: 1,
    w: inputW,
    h: inputH,
    multiplier: 1,
  };
};
export function resizeCanvasFitScreen(
  ScreenWidth: number,
  ScreenHeight: number,
  WidthImage: number,
  HeightImage: number
) {
  if (!ScreenHeight || !ScreenWidth) return;
  const ratio = WidthImage / ScreenWidth;
  const img = {
    sX: WidthImage / ratio / WidthImage,
    sY: HeightImage / ratio / HeightImage,
    w: WidthImage / ratio,
    h: HeightImage / ratio,
    multiplier: ratio,
  };
  return img;
}

export function millisecondsToMinutesAndSeconds(milliseconds: number) {
  if (milliseconds < 1000) {
    return `${(milliseconds / 1000).toFixed(1)} second`;
  }

  const totalSeconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  return `${minutes}m, ${seconds}s`;
}

export function genTimePerJob(millisecondsArray: number[]) {
  const result = [];
  for (const item of millisecondsArray) {
    result.push(millisecondsToMinutesAndSeconds(item));
  }
  return result;
}

const brushColor = "rgba(0, 71, 255, 0.7)";
export function getDrawCursor(brushSize: number) {
  if (brushSize < 5) brushSize = 5;
  const circle = `
      <svg
        height="${brushSize}"
        fill="${brushColor}"
        viewBox="0 0 ${brushSize * 2} ${brushSize * 2}"
        width="${brushSize}"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="50%"
          cy="50%"
          r="${brushSize}" 
        />
      </svg>
    `;
  return `data:image/svg+xml;base64,${window.btoa(circle)}`;
}

export async function resizeImageBase64(base64Image: string): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    // Create a new image object
    const img = new Image();
    // Set the source of the image
    img.src = base64Image;

    // When the image is loaded, create a fabric.Image and resize it
    img.onload = () => {
      const fabricImg = new fabric.Image(img, {
        backgroundColor: "white",
        width: img.width,
        height: img.height,
      });

      // Resize the image with the specified multiplier
      const resizedBase64 = fabricImg.toDataURL({
        format: "png",
      });
      resolve(resizedBase64);
    };
    // Handle loading errors
    img.onerror = (error: any) => {
      reject(`Error loading image: ${error}`);
    };
  });
}
export async function convertBase64Image(imageBase64: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx?.drawImage(img, 0, 0);

      const imageData = ctx?.getImageData(0, 0, canvas.width, canvas.height);
      if (imageData) {
        const data = imageData.data;

        for (let i = 0; i < data.length; i += 4) {
          // Process the image pixels here
          // Example: converting white pixels to blue
          if (data[i] === 255 && data[i + 1] === 255 && data[i + 2] === 255) {
            data[i] = 0; // Red
            data[i + 1] = 0; // Green
            data[i + 2] = 255; // Blue
            data[i + 3] = 255; // Alpha (opaque)
          } else {
            data[i + 3] = 0; // Alpha
          }
        }

        ctx && ctx.putImageData(imageData, 0, 0);
        resolve(canvas.toDataURL("image/png"));
      }
    };

    img.onerror = () => {
      reject(new Error("Failed to load image"));
    };
    img.src = imageBase64;
  });
}

export async function convertBase64ImageBlueToWhite(
  imageBase64: string,
  opacity = 1
): Promise<string> {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx?.drawImage(img, 0, 0);

      const imageData = ctx?.getImageData(0, 0, canvas.width, canvas.height);
      if (imageData) {
        const data = imageData.data;

        for (let i = 0; i < data.length; i += 4) {
          // Process the image pixels here
          // Example: converting blue pixels to white
          if (data[i] === 0 && data[i + 1] === 0 && data[i + 2] === 0) {
            continue;
          }

          data[i] = 255; // Red
          data[i + 1] = 255; // Green
          data[i + 2] = 255; // Blue
          data[i + 3] = 255; // Alpha (opaque)
        }
        ctx && ctx.putImageData(imageData, 0, 0);
        resolve(canvas.toDataURL("image/png"));
      }
    };

    img.onerror = () => {
      reject(new Error("Failed to load image"));
    };
    img.src = imageBase64;
  });
}

export const resizeImageMax2k = (base64String: string) => {
  return new Promise((resolve, reject) => {
    const file = dataURLtoFile(base64String, "image.jpg");
    Resizer.imageFileResizer(
      file,
      2000, // max width
      2000, // max height
      "JPEG", // format
      100, // quality
      0, // rotation
      (uri: any) => {
        resolve(uri);
      },
      "base64"
    );
  });
};
export const resizeImageByWidthAndHeight = (
  base64String: string,
  w: number,
  h: number
) => {
  return new Promise((resolve, reject) => {
    const file = dataURLtoFile(base64String, "image.jpg");
    Resizer.imageFileResizer(
      file,
      w, // max width
      h, // max height
      "JPEG", // format
      100, // quality
      0, // rotation
      (uri: any) => {
        resolve(uri);
      },
      "base64"
    );
  });
};

const dataURLtoFile = (dataurl: any, filename: string) => {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};
export const checkForTrue = (array: any, property: string) => {
  for (let obj of array) {
    if (obj[property] === true) {
      return true;
    }
  }
  return false;
};
export const getObjectWithTrueValue = (array: any) => {
  for (let obj of array) {
    if (obj.is_default === true) {
      return obj.bg_url;
    }
  }
};
export const checkHasValueInArrayListBg = (
  arrayObjects: any,
  searchString: string
) => {
  // Kiểm tra xem có object nào có bg_url === searchString hay không
  const exists = arrayObjects.some(function (obj: any) {
    return obj.bg_url === searchString;
  });
  return exists;
};
export const MAX_LENGTH_INPUT_PROMPT = 100;
export const formatPrice = (number: number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};
export const convertBlobToBase64 = (blob: any) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};
let isScrollDisabled = false; // Flag to track scroll state
const body = document.getElementById("root") as HTMLBodyElement;
export const disableScroll = () => {
  isScrollDisabled = true;
  body.addEventListener("wheel", preventWheelEvent);
};

export const enableScroll = () => {
  isScrollDisabled = false;
  body.removeEventListener("wheel", preventWheelEvent);
};

const preventWheelEvent = (e: WheelEvent) => {
  if (isScrollDisabled) {
    e.preventDefault();
  }
};
export const disableEvent = (className: string) => {
  const elements = document.getElementsByClassName(className) as any;
  elements[0].style.pointerEvents = "none";
};
export const enableEvent = (className: string) => {
  const elements = document.getElementsByClassName(className) as any;
  elements[0].style.pointerEvents = "auto";
};

export function calculateReadingTime(htmlContent: string, language = "en") {
  // Convert HTML to plain text
  const plainText = htmlContent.replace(/<[^>]+>/g, "");

  // Calculate the number of words in the text
  const wordCount = plainText.split(/\s+/).length;

  // Average reading speed in words per minute (adjust as needed)
  const wordsPerMinute = 200;

  // Calculate the reading time in minutes
  const readingTimeMinutes = wordCount / wordsPerMinute;

  // Round up to the nearest whole number
  const readingTimeRounded = Math.ceil(readingTimeMinutes);

  // Calculate reading time in seconds
  const readingTimeSeconds = Math.ceil(readingTimeMinutes * 60);

  // Determine the language
  const isVietnamese = language.toLowerCase() === "vi";

  // Format the reading time text
  let readingTimeText;
  if (readingTimeMinutes < 1) {
    readingTimeText = isVietnamese
      ? `Đọc trong ${readingTimeSeconds} giây`
      : `Read in ${readingTimeSeconds} seconds`;
  } else if (readingTimeMinutes === 1) {
    readingTimeText = isVietnamese ? "Đọc trong 1 phút" : "Read in 1 minute";
  } else {
    readingTimeText = isVietnamese
      ? `Đọc trong ${readingTimeRounded} phút`
      : `Read in ${readingTimeRounded} minutes`;
  }

  return readingTimeText;
}
export async function replaceWhiteWithColor(
  grayscaleBase64: string,
  colorBase64: string
): Promise<string | undefined> {
  // Load ảnh đen trắng và ảnh màu từ base64
  const grayscaleImage = await loadImageFromBase64(grayscaleBase64);
  const colorImage = await loadImageFromBase64(colorBase64);

  // Tạo canvas để vẽ ảnh
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  // Đặt kích thước của canvas bằng kích thước của ảnh màu
  canvas.width = colorImage.width;
  canvas.height = colorImage.height;
  if (!ctx) return;
  // Vẽ ảnh màu lên canvas
  ctx.drawImage(colorImage, 0, 0);

  // Lấy dữ liệu pixel của ảnh màu và ảnh đen trắng
  const colorImageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const colorData = colorImageData.data;

  const grayscaleCanvas = document.createElement("canvas");
  grayscaleCanvas.width = colorImage.width;
  grayscaleCanvas.height = colorImage.height;
  const grayscaleCtx = grayscaleCanvas.getContext("2d");
  if (!grayscaleCtx) return;
  grayscaleCtx.drawImage(grayscaleImage, 0, 0);
  const grayscaleImageData = grayscaleCtx.getImageData(
    0,
    0,
    canvas.width,
    canvas.height
  );
  const grayscaleData = grayscaleImageData.data;

  // Thay đổi pixel của ảnh đen trắng nếu pixel tương ứng trong ảnh đen trắng là màu trắng
  for (let i = 0; i < grayscaleData.length; i += 4) {
    if (
      grayscaleData[i] === 255 &&
      grayscaleData[i + 1] === 255 &&
      grayscaleData[i + 2] === 255
    ) {
      grayscaleData[i] = colorData[i];
      grayscaleData[i + 1] = colorData[i + 1];
      grayscaleData[i + 2] = colorData[i + 2];
    }
  }

  // Đặt lại dữ liệu pixel của ảnh đen trắng sau khi chỉnh sửa
  grayscaleCtx.putImageData(grayscaleImageData, 0, 0);

  // Chuyển canvas thành ảnh base64
  const base64 = grayscaleCanvas.toDataURL("image/jpeg");
  return base64;
}

function loadImageFromBase64(base64: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = base64;
  });
}
export const scaleImageToMax1024 = async (input: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const hasHTTPS = /^https:\/\//.test(input);
    if (hasHTTPS) {
      resolve(input);
    } else {
      const img = new Image();
      img.onload = () => {
        const MAX_SIZE = 1024;

        let width = img.width;
        let height = img.height;

        // Calculate aspect ratio
        const aspectRatio = width / height;

        // Calculate new width and height to fit within MAX_SIZE while maintaining aspect ratio
        if (width > height) {
          width = MAX_SIZE;
          height = width / aspectRatio;
        } else {
          height = MAX_SIZE;
          width = height * aspectRatio;
        }

        // Ensure both width and height are divisible by 8
        width = Math.floor(width / 8) * 8;
        height = Math.floor(height / 8) * 8;

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = width;
        canvas.height = height;
        if (!ctx) return;
        ctx.drawImage(img, 0, 0, width, height);
        const resizedBase64 = canvas.toDataURL("image/jpeg");
        resolve(resizedBase64);
      };
      img.onerror = (error) => {
        reject(error);
      };

      img.src = input;
    }
  });
};
export const resizeImageToMaxSize768x1024 = async (
  input: string
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const hasHTTPS = /^https:\/\//.test(input);
    if (hasHTTPS) {
      resolve(input);
    } else {
      try {
        scaleImageToMax1024(input).then((b64) => {
          const base64OutPut = cropImageTo1024(b64);
          resolve(base64OutPut);
        });
      } catch (error) {
        resolve(input);
      }
    }
  });
};
export const cropImageTo1024 = async (input: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const hasHTTPS = /^https:\/\//.test(input);
    if (hasHTTPS) {
      resolve(input);
    } else {
      const img = new Image();
      img.onload = function () {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = MAX_SIZE_WIDTH;
        canvas.height = MAX_SIZE_HEIGHT;

        const imgWidth = img.width;
        const imgHeight = img.height;
        if (!ctx) return;
        // Check if the image is smaller than the output size
        if (imgWidth < MAX_SIZE_WIDTH || imgHeight < MAX_SIZE_HEIGHT) {
          // Scale the image to fit the output size if it is smaller
          const scale = Math.max(
            MAX_SIZE_WIDTH / imgWidth,
            MAX_SIZE_HEIGHT / imgHeight
          );
          const scaledWidth = imgWidth * scale;
          const scaledHeight = imgHeight * scale;
          const offsetX = (scaledWidth - MAX_SIZE_WIDTH) / 2;
          const offsetY = (scaledHeight - MAX_SIZE_HEIGHT) / 2;

          ctx.drawImage(img, -offsetX, -offsetY, scaledWidth, scaledHeight);
        } else {
          // Calculate offset center
          const cropX = Math.max(0, (imgWidth - MAX_SIZE_WIDTH) / 2);
          const cropY = Math.max(0, (imgHeight - MAX_SIZE_HEIGHT) / 2);

          // Draw image
          ctx.drawImage(
            img,
            cropX,
            cropY,
            MAX_SIZE_WIDTH,
            MAX_SIZE_HEIGHT,
            0,
            0,
            MAX_SIZE_WIDTH,
            MAX_SIZE_HEIGHT
          );
        }

        // Convert canvas to base64 and resolve the promise
        const outputBase64 = canvas.toDataURL();
        resolve(outputBase64);
      };
      img.onerror = (error) => {
        reject(error);
      };

      img.src = input;
    }
  });
};
export const resizeImageTo1024 = async (input: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const MAX_SIZE = 1024;
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > MAX_SIZE) {
          height *= Math.round(MAX_SIZE / width);
          width = MAX_SIZE;
        }
      } else {
        if (height > MAX_SIZE) {
          width *= Math.round(MAX_SIZE / height);
          height = MAX_SIZE;
        }
      }

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = width;
      canvas.height = height;
      if (!ctx) return;
      ctx.drawImage(img, 0, 0, width, height);
      const resizedBase64 = canvas.toDataURL("image/jpeg");
      resolve(resizedBase64);
    };
    img.onerror = (error) => {
      reject(error);
    };

    img.src = input;
  });
};
export const base64ToFile = (base64String: string, fileName: string): File => {
  // Tách chuỗi base64 để lấy ra phần dữ liệu
  const base64Data = base64String.split(";base64,").pop();

  // Tạo một mảng byte từ dữ liệu base64
  //@ts-ignore
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // Tạo đối tượng Blob từ mảng byte
  const blob = new Blob([byteArray], { type: "image/jpeg" });

  // Tạo đối tượng File từ Blob
  return new File([blob], fileName, { type: "image/jpeg" });
};

export async function getCroppedImg(imageSrc: string, pixelCrop: any, mul = 1) {
  const image: any = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  // set canvas size to match the image size
  canvas.width = image.width;
  canvas.height = image.height;

  // draw the image
  ctx.drawImage(image, 0, 0);

  const croppedCanvas = document.createElement("canvas");
  const croppedCtx = croppedCanvas.getContext("2d");

  if (!croppedCtx) {
    return null;
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width / mul;
  croppedCanvas.height = pixelCrop.height / mul;

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width / mul,
    pixelCrop.height / mul
  );

  // As Base64 string
  return croppedCanvas.toDataURL("image/png");
}

// Function to load an Image from a URL or base64 string
export const createImage = (url: string) => {
  if (url.includes("data:image")) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.src = url;
    });
  } else {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url + "?not-from-cache-please";
    });
  }
};
export function base64ToBlob(base64: string, contentType: string) {
  // Remove any data URL scheme (e.g., "data:image/png;base64,")
  const base64WithoutPrefix = base64.split(",")[1];

  const byteCharacters = atob(base64WithoutPrefix);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}
