import axios from "axios";
import { IFeatureWhitelist, Titles } from "../utils/interface";
import { httpDelete, httpGet, httpPost, httpPut } from "./http.service";

export default class FashionService {
  private static instance: FashionService;
  private constructor() { }
  public static getInstance() {
    if (!this.instance) {
      this.instance = new FashionService();
    }
    return this.instance;
  }
  async getTemplates(clothesType: string, lang: string) {
    const { data } = await httpGet(
      `/fashion/template-by-clothes?template=${clothesType}&lang=${lang}`
    );
    return data.data;
  }
  async addFeatureToWhitelist(body: IFeatureWhitelist) {
    const { data } = await httpPost("/fashion/add-whitelist", body);
    return data;
  }
  async getJobSegment(id: string) {
    const { data } = await httpGet(`/fashion/segment-v3?s_id=${id}`);
    return data;
  }
  async getCustomItems(
    lang: string,
    contentModelCode?: string,
    clothesType?: string
  ) {
    const { data } = await httpGet(
      `/fashion/custom-items?lang=${lang}&contentModelCode=${contentModelCode}&clothesType=${clothesType}`
    );
    return data.data;
  }
  async getSegment(image: string) {
    const { data } = await httpPost("/fashion/segment-v3", {
      image,
    });
    return data;
  }
  async processSegment(body: {
    type: string;
    image: string;
    version?: number;
  }) {
    const { data } = await httpPost("/fashion/segment", body);
    return data.data;
  }

  async listenSegmentResult(jobId: string) {
    const { data } = await httpGet(`/fashion/segment?s_id=${jobId}`);
    return data.data;
  }

  async processImg2Img(body: any) {
    const { data } = await httpPost("/fashion/generate-fashion-image", body);
    return data.data;
  }

  async listenImage(taskId: string) {
    const { data } = await httpPost("/gateway/listen-image", {
      specialId: taskId,
    });
    return data.data;
  }
  async listenImageRemove(taskId: string) {
    const { data } = await httpPost("/fashion/listen-image", {
      specialId: taskId,
    });
    return data.data;
  }
  async cancelJobGen(taskId: string) {
    const { data } = await httpPost("/fashion/cancel-task", {
      specialId: taskId,
    });
    return data.data;
  }
  async getImages(page: number, pageSize: number, lang: string) {
    const { data } = await httpGet(
      `/fashion/images?page=${page}&pageSize=${pageSize}&lang=${lang}`
    );
    return data.data;
  }

  async getImage(id: string, lang: string) {
    const { data } = await httpGet(`/fashion/image/${id}?lang=${lang}`);
    return data.data;
  }

  async deleteImage(id: string) {
    const { data } = await httpDelete(`/fashion/image/${id}`);
    return data;
  }
  //new-flow
  async getListPoseByType(clothesType: string, lang: string) {
    const { data } = await httpGet(
      `/fashion/poses?type=${clothesType}&lang=${lang}`
    );
    return data;
  }
  async getOrders(limit: number, page: number) {
    const { data } = await httpGet(`/order?limit=${limit}&page=${page}`);
    return data.data;
  }
  async getRef(limit: number, page: number) {
    const { data } = await httpGet(
      `/account/list-ref?limit=${limit}&page=${page}`
    );
    return data.data;
  }
  async getAffiliate(
    limit: number,
    page: number,
    startDate: number,
    endDate: number,
    sorter: any,
    searchText: string
  ) {
    const { data } = await httpPost(`/affiliate`, {
      limit,
      page,
      startDate,
      endDate,
      sorter,
      searchText,
      timeZoneOffset: new Date().getTimezoneOffset(),
    });
    return data;
  }

  async getExportData(
    startDate: number,
    endDate: number,
    sorter: any,
    searchText: string
  ) {
    const { data } = await httpPost(`/affiliate/export`, {
      startDate,
      endDate,
      sorter,
      searchText,
    });
    return data;
  }

  async cancelSub(orderId: string) {
    const { data } = await httpPost("/payment/cancel-subscription", {
      orderId,
    });
    return data.data;
  }

  async getGeneratingImages() {
    const { data } = await httpGet("/fashion/generating-images");
    return data.data;
  }

  async processUpscale(body: any) {
    const { data } = await httpPost("/fashion/upscale", body);
    return data.data;
  }

  async processSwapface(body: any) {
    const { data } = await httpPost("/fashion/swapface", body);
    return data.data;
  }

  async processCorrectPart(body: any) {
    const { data } = await httpPost("/fashion/correct-part", body);
    return data.data;
  }

  async processRemoveObject(body: any) {
    const { data } = await httpPost("/fashion/remove-object", body);
    return data.data;
  }

  async processRemoveRedundantDetail(body: any) {
    const { data } = await httpPost("/fashion/remove-redundant-detail", body);
    return data.data;
  }

  async processBorderSmooth(body: any) {
    const { data } = await httpPost("/fashion/smooth-border", body);
    return data.data;
  }

  async removeWaterMarkManual(body: any) {
    const { data } = await httpPost("/fashion/manual-remove-watermark", {
      ...body,
      ignoreResize: true,
    });
    return data.data;
  }

  async removeWaterMarkAuto(body: any) {
    const { data } = await httpPost("/fashion/auto-remove-watermark", {
      ...body,
      ignoreResize: true,
    });
    return data.data;
  }
  async getRemoveAuto(body: any) {
    const { data } = await httpPost(
      "/fashion/auto-remove-watermark-mask",
      body
    );
    return data.data;
  }

  async getSegmentDetail(id: string) {
    const { data } = await httpGet(`/fashion/segment/${id}`);
    return data;
  }

  async processUpscaleV2(image: any) {
    const { data } = await httpPost("/fashion/v2/upscale", { image });
    return data.data;
  }

  async getBorderAuto(body: any) {
    const { data } = await httpPost("/fashion/auto-border", body);
    return data;
  }

  async getDataPose(body: any) {
    const { data } = await httpPost("/fashion/detect-pose", body);
    return data.data;
  }
  //VTO

  async createVTOOutfit(body: any) {
    const { data } = await httpPost("vto/save-outfit", body);
    return data;
  }

  async updateVTOOutfit(body: any) {
    const { data } = await httpPost("vto/edit-outfit", body);
    return data;
  }

  async getListVTOOutfits(
    page: number,
    limit: number,
    category: string,
    search: string,
    order: string,
    orderType: number
  ) {
    let endPoint = `vto/list-outfit?page=${page}&limit=${limit}&category=${category}&keyword=${search}&order=${order}&orderType=${orderType}`;
    if (category === "all") {
      endPoint = `vto/list-outfit?page=${page}&limit=${limit}&keyword=${search}&order=${order}&orderType=${orderType}`;
    }
    const { data } = await httpGet(endPoint);
    return data;
  }

  async deleteVTOOutfit(listID: string[]) {
    const { data } = await httpPost("/vto/delete-outfits", {
      outfitCodes: listID,
    });
    return data;
  }

  async getVTOCategories() {
    const { data } = await httpGet("/vto/outfit-category");
    return data;
  }

  async createVTOCategory(categoryName: string, lang: string) {
    const { data } = await httpPost("/vto/outfit-category", {
      category_name: categoryName,
      lang,
    });
    return data;
  }

  async deleteVTOCategory(categoryName: string) {
    const { data } = await httpPost(`/vto/delete-outfit-category`, {
      categoryId: categoryName,
    });
    return data;
  }

  async deleteVTOImageItem(outfitId: string) {
    const { data } = await httpPost(`/vto/delete-outfit`, {
      outfitId,
    });
    return data;
  }

  async updateVTOCategory(
    oldCategoryName: string,
    newCategoryName: string,
    lang: string
  ) {
    const { data } = await httpPut(`/vto/outfit-category`, {
      oldCategoryName,
      newCategoryName,
      lang,
    });
    return data;
  }

  async getVTOSetting(type: string) {
    const { data } = await httpGet(`/vto/setting?settingType=${type}`);
    return data;
  }

  async updateVTOSettings(dataInput: any) {
    const { data } = await httpPost(`/vto/setting`, dataInput);
    return data;
  }
  async uploadFileS3(file: any, signedRequest: string, url: string) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", signedRequest);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            console.log("File uploaded successfully.");
            resolve(url);
          } else {
            console.log("Could not upload file.");
            reject(new Error("File upload failed."));
          }
        }
      };

      xhr.send(file);
    });
  }
  async uploadImage(fileName: string, fileType: string) {
    const timestamp = new Date().getTime();
    const url = `${process.env.REACT_APP_BE}/dashboard/signed-url?fileName=${timestamp}-${fileName}&fileType=${fileType}`;

    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      // Xử lý lỗi nếu có
      console.error("Error uploading image:", error);
      throw error; // Throw để chuyển tiếp lỗi cho phía gọi hàm xử lý
    }
  }

  async getUIConfig() {
    const { data } = await httpGet(`vto/ui-config`);
    return data;
  }
  async saveExampleImages(
    exampleImages: string[]
  ) {
    const { data } = await httpPost(`/vto/ui-config`, {
      exampleImages
    });
    return data;
  }
  async saveUIConfig(
    urlLogo?: string,
    fontSite?: string,
    titleHeader?: Titles,
    primaryColor?: string,
    backgroundColor?: string,
  ) {
    const { data } = await httpPost(`/vto/ui-config`, {
      urlLogo,
      fontSite,
      titleHeader,
      primaryColor,
      backgroundColor,
    });
    return data;
  }
  async getConfig() {
    const { data } = await httpGet(`/vto/ui-config`);
    return data;
  }
  async getPartnerProgram() {
    const { data } = await httpGet(`/affiliate/partner-affiliate-program`);
    return data;
  }

  async setAffiliate(userId: string, programId: string) {
    const { data } = await httpPost(`/affiliate/set-affiliate`, {
      userId,
      programId,
    });
    return data;
  }
  async getDataReportVTO(
    page: number,
    limit: number,
    searchKey: string,
    cateId: string,
    startTimestamp: number,
    endTimestamp: number,
    interval?: string,
    shopName?: string,
    sortingObj?: {
      sortCol?: string;
      sortType?: number;
    }
  ) {
    const { data } = await httpGet(
      `vto/report?page=${page}&limit=${limit}&searchKey=${searchKey}&categoryId=${cateId}&startTimestamp=${startTimestamp}&endTimestamp=${endTimestamp}&interval=${interval ?? "7d"
      }&shopName=${shopName ?? ""}&sortCol=${sortingObj?.sortCol ?? ""
      }&sortType=${sortingObj?.sortType ?? 1}`
    );
    return data;
  }

  async updateShopUrl(shopUrl: string) {
    const { data } = await httpPost(`/vto/update-shop-url`, { shopUrl });
    return data;
  }
  async removeBackground(image: string) {
    const { data } = await httpPost("/fashion/remove-bg", {
      image,
    });
    return data;
  }
  async trainLora(
    outfit_id: string,
    images: string[],
    params: string,
    language: string
  ) {
    const { data } = await httpPost("/vto/lora/train", {
      outfit_id,
      images,
      params: "{}",
      language,
    });
    return data;
  }
  async listenTrainingLora(id: string, language: string) {
    const { data } = await httpGet(`/vto/lora/${id}?language=${language}`);
    return data;
  }
  async getImageTrainingByOutfitId(outfit_id: string, language: string) {
    const { data } = await httpGet(
      `/vto/lora/?outfit_id=${outfit_id}&language=${language}`
    );
    return data;
  }
  async getConfigGuide() {
    const { data } = await httpGet("/vto/configurations");
    return data;
  }
  async getCaptionByImage(image: string) {
    const { data } = await httpPost("/fashion/caption-image", {
      image,
    });
    return data;
  }

  async createLoraForShopUser(body: any) {
    const { data } = await httpPost("/vto/lora", body);
    return data;
  }
  async getConfiguration() {
    const { data } = await httpGet("/config/modeli");
    return data;
  }
  async cancelTrainLora(id: string, lang: string) {
    const { data } = await httpPost(
      `/vto/lora/${id}/cancel?language=${lang}`,
      {}
    );
    return data;
  }
}
