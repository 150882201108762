import 'moment-timezone';
import 'moment/locale/vi';
import './styles.css';

import { Button, Empty, Modal, Pagination, Progress, Select, Spin, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ReactCompareImage from 'react-compare-image';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';
import { DeleteOutlined, LoadingOutlined, ThunderboltOutlined, CopyOutlined, BugOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import { logEvent } from '@firebase/analytics';
import backArrow from '../../assets/icons/arrow_new_1.svg';
import nextArrow from '../../assets/icons/arrow_new_2.svg';
import close from '../../assets/icons/close.svg';
import logoModeli from '../../assets/icons/logo-modeli.svg';
import refresh from '../../assets/icons/refresh.svg';
import trash from '../../assets/icons/trash.svg';
import iconUpload from '../../assets/icons/upload-cloud.png';
import analytics from '../../firebase';
import { setIsAutoPose } from '../../redux/app/appAutoPose';
import { setIsAuto } from '../../redux/app/appAutoSegment';
import { setLoadingApp } from '../../redux/app/loadingApp';
import { setTypeModel } from '../../redux/app/selectTypeModel';
import { useAppSelector } from '../../redux/hooks/useAppSelector';
import FashionService from '../../services/fashion.service';
import {
  FashionToolType, TypeEditor, WhitelistFeatureEnum, WhitelistTypeEnum
} from '../../utils/enum';
import {
  customizeKey, genTimePerJob, generateRandomNumberString, millisecondsToMinutesAndSeconds,
  resizeImageMax2k,
  urlToBase64,
  convertHeicToJpg,
  convertBlobToBase64
} from '../../utils/function';
import { IFeatureWhitelist, IImage, IImageGen } from '../../utils/interface';
import NotifyController from '../../utils/toast';
import PopupAddToWhiteList from '../PopupAddToWhiteList';
import ModalEditor from '../UploadImage/ModalEditor';
import BoxTagName from './BoxTagName';
import CollapseCustomize from './CollapseCustomize';
import { toolEditorImage } from '../../redux/app/toolEditor';
import { userSaga } from '../../redux/app/authSaga';
import ModalTool from '../../pages/Editor/Tools/ModalTool';
import { setIsDisplayHeader } from '../../redux/app/appScroll';
import AccountService from '../../services/account.service';

type ListRenderedImagesProps = {
  listImages: IImage[],
  listGenerating: IImageGen[],
  getImages: Function,
  setFashionParams: Function,
  user: any,
  resultRef: any,
  setListGenerating: any,
  handlePageChange: any,
  pagination: any,
  openPaymentModal: boolean,
  setOpenPaymentModal: Function,
  setIsGenerating: Function
  openModalTool: boolean;
  setOpenModalTool: Function
}
const permissionTypeRemoveObject = {
  feature: WhitelistFeatureEnum.REMOVE_OBJECT,
  type: WhitelistTypeEnum.DATA
}

const ListRenderedImages = (props: ListRenderedImagesProps) => {
  const dispatch = useDispatch();
  const timezone = moment.tz.guess();
  const { t, i18n } = useTranslation()
  const isGenerating = useAppSelector(store => store.isGeneratingImage.isGen)
  const dataCost = useAppSelector(store => store.costFeature)
  const isGoHome = useAppSelector(store => store.isGeneratingImage.isGoHome)
  const userInfo = useAppSelector((store) => store.user);
  const fashionParamsReducer = useAppSelector((store) => store.select);
  const isAdmin = useAppSelector((store) => store.user.roles).includes("ADMIN");
  const currentCoins = userInfo.coins

  const [disableGenerateButton, setDisableGenerateButton] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [imageParams, setImageParams] = useState<any>();
  const [imageSFParams, setImageSFParams] = useState<any>();
  const [selectedImage, setSelectedImage] = useState('');
  const [listDataPreview, setListDataPreview] = useState<any>([]);
  const [selectedOriginImage, setSelectedOriginImage] = useState('');
  const [isPreviewModalOpen, setPreviewModal] = useState(false);
  const [isUpscaleModalOpen, setUpscaleModal] = useState(false);
  const [isSwapfaceModalOpen, setSwapfaceModal] = useState(false);
  const [isShowFormWhiteList, setIsShowFormWhiteList] = useState(false)
  const [upscaleValue, setUpscaleValue] = useState(1.5);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [cropData, setCropData] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [image, setImage] = useState('#');
  const [listImage, setListImage] = useState<string[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [typePermission, setTypePermission] = useState<IFeatureWhitelist>(permissionTypeRemoveObject)

  const next = () => {
    if (currentIndex < listImage.length - 1) {
      const newIndex = currentIndex + 1;
      setSelectedImage(listImage[newIndex]);
      setCurrentIndex(newIndex);
      setListDataPreview([listDataPreview[0], listImage[newIndex]])
    }
  };

  const previous = () => {
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      setSelectedImage(listImage[newIndex]);
      setCurrentIndex(newIndex);
      setListDataPreview([listDataPreview[0], listImage[newIndex]])
    }
  };

  const handleDownload = (downloadLink: string) => {
    const link = document.createElement('a');
    link.href = downloadLink;
    link.click();
    logEvent(analytics, 'save_image')
  }

  const handleUpscale = (img: IImage, link: string) => {
    setImageParams(img);
    setSelectedImage(img.url_v2[currentIndex]);
    const image: HTMLImageElement = new window.Image();
    image.src = img.url_v2[currentIndex];
    image.onload = () => {
      setWidth(image.width);
      setHeight(image.height);
    };
    setUpscaleModal(true);
    logEvent(analytics, 'popup_upscale')
  }


  const onDelete = (id: string) => {
    swal({
      title: t('be_careful'),
      text: t('want_to_delete'),
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: {
          text: t('cancel'),
          value: null,
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: t('delete'),
          value: true,
          visible: true,
          closeModal: true,
        },
      },
    }).then(async (result) => {
      if (result) {
        await FashionService.getInstance().deleteImage(id);
        props.getImages();
        swal(t('delete_successfully'), '', 'success');
        logEvent(analytics, 'delete_output')
      }
    });
  };

  const onCopyLink = (id: string, type: string) => {
    if (type === "link") {
      navigator.clipboard.writeText(`${process.env.REACT_APP_CLIENT_URL}/my-work/${id}`);
      NotifyController.success('Copy link successfully')
    } else if (type === "id") {
      navigator.clipboard.writeText(`${id}`);
      NotifyController.success('Copy id successfully')
    }
  }

  const onCopySetting = async (image: any) => {
    dispatch(setLoadingApp.setLoading(true))
    try {
      let res;
      if (image.samSpecialId) res = await FashionService.getInstance().getSegmentDetail(image.samSpecialId);
      if (!image.poseImage || !image.poseData || image.autoPose === undefined) {
        const body = { image: await urlToBase64(image.originImageUrl), width: image.width, height: image.height, poseId: image.poseId }
        const dataPose = await FashionService.getInstance().getDataPose(body);
        if (dataPose.images[0].startsWith('data:image')) {
          // If it already contains base64 data, assign it
          image.poseImage = dataPose.images[0];
        } else {
          // If it doesn't contain base64 data, append it
          image.poseImage = 'data:image/png;base64,' + dataPose.images[0];
        }
        image.poseData = dataPose.poses[0].people[0].pose_keypoints_2d;
        image.autoPose = true;
      }
      const element = document.getElementById('upload-image-section');
      element && element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      dispatch(setTypeModel.setTypeModel(image.clothesCode));
      dispatch(setTypeModel.setTypeName(image.templateType));
      dispatch(setTypeModel.setOriginImageUrl(image.originImageUrl));
      dispatch(setIsAutoPose.setIsAutoPose(image.autoPose))
      dispatch(setTypeModel.setFashionParams({
        templateName: image.templateName,
        templateCode: image.templateCode,
        prompt: image.prompt,
        negativePrompt: image.negativePrompt,
        samplingStep: image.steps,
        denoisingStrength: image.denoisingStrength,
        controlWeight: image.controlWeight,
        BGWeight: image.BGWeight,
        selectedItems: image.data,
        isClickCopySetting: !fashionParamsReducer.isClickCopySetting,
        segment: res && res.data.url_v2,
        annotations: res && res.data.annotations,
        samSpecialId: image.samSpecialId,
        numberOfImages: image.url_v2.length || 4,
        poseId: image.poseId,
        poseImage: image.poseImage,
        poseData: image.poseData,
        width: image.width,
        height: image.height,
        backgroundImage: image.backgroundImage,
      }));
      dispatch(setIsAuto.setIsAuto(res && (res.data.autoMask === undefined ? true : res.data.autoMask)));
      dispatch(setLoadingApp.setLoading(false))
    } catch (error) {
      dispatch(setLoadingApp.setLoading(false))
      NotifyController.error('something_wrong')
    }
  }

  const tempDisableButton = () => {
    setDisableGenerateButton(true);
    setTimeout(() => {
      setDisableGenerateButton(false);
      setUpscaleModal(false);
      setSwapfaceModal(false);
      setCropData('');
      setPreviewModal(false)
    }, 3000);
  };
  // const updateUserCredits = async () => {
  //   try {
  //     const { coins } = await AccountService.getInstance().getUserInfo();
  //     dispatch(userSaga.setCredits(coins));
  //   } catch (error) {
  //     swal(t('something_wrong'), "", "error");
  //   }
  // }
  const handleOk = async () => {
    try {
      if (userInfo.coins < dataCost.upscale) {
        NotifyController.warning(t('Not_enough_credit'))
        props.setOpenPaymentModal(true);
        setUpscaleModal(false);
        setCropData('');
        return;
      }
      if (props.listGenerating.length === userInfo.maxConcurrent) {
        swal(t('queue_full'), '', 'warning')
        return;
      }
      tempDisableButton();
      const body = {
        imageUrl: selectedImage,
        upscaleMultiplier: upscaleValue,
        id: imageParams.id
      }
      const generatingImage = [
        {
          specialId: generateRandomNumberString(10),
          negativePrompt: '',
          prompt: '',
          percent: 0,
          templateName: imageParams.templateName,
          templateType: imageParams.templateType,
          text: 'Generating...',
          numberOfImages: Array.from({ length: 1 }, (_, index) => index)
        },
        ...props.listGenerating
      ];
      props.setListGenerating(generatingImage);
      await FashionService.getInstance().processUpscale(body);
      dispatch(userSaga.setCredits(currentCoins - dataCost.upscale));

      setUpscaleModal(false)
      setPreviewModal(false)
      const element = document.getElementById('top-list');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
      logEvent(analytics, 'upscale_success')
      props.setIsGenerating(true);
    } catch (error: any) {
      setDisableGenerateButton(false);
      swal(t(error.message), '', 'error')
      logEvent(analytics, `server_error_${customizeKey(error.message)}`)
    }
  };

  const handleCancel = () => {
    setUpscaleModal(false);
    setSwapfaceModal(false);
    setCropData('');
  };

  const handleChange = (value: number) => {
    setUpscaleValue(value);
  };


  const customCloseIcon = (
    <img style={{ margin: '15px 40px 0 0', fill: 'black' }} src={close} />
  );

  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />;


  const handleImageClick = (listImg: Array<string>, imageUrl: string, image: IImage, originImage: string, Generating: boolean) => {
    setSelectedImage(imageUrl);
    setPreviewModal(true);
    setGenerating(Generating)
    logEvent(analytics, 'popup_preview_image')
    setSelectedOriginImage(originImage);
    setListImage(listImg);
    setCurrentIndex(listImg.indexOf(imageUrl));
    setListDataPreview([image, imageUrl])
  };

  const handleCloseModal = () => {
    setSelectedImage('');
    setSelectedOriginImage('');
    setPreviewModal(false);
  };

  const handleSwapface = (img: IImage) => {
    setSelectedImage(img.url_v2[currentIndex]);
    setImageSFParams(img)
    setSwapfaceModal(true);
    logEvent(analytics, 'popup_swapface')
  }

  const onClickUploadImage = () => {
    const inputUpload = document.getElementById('input-upload-image-swapface') as HTMLInputElement;
    inputUpload.click();
  };


  const handleImageUpload = async (e: any) => {
    e.preventDefault();
    let files = e.target?.files;
    if (!files || !files.length) {
      console.warn("No file selected")
      return
    }
    const reader = new FileReader();
    reader.onload = async (e: any) => {
      dispatch(setLoadingApp.setLoading(true));
      let imageUrlFinal = reader.result as string;
      let isHeic = imageUrlFinal.startsWith('data:application/octet-stream;base64')
      if (isHeic) {
        const response = await fetch(reader.result as any);
        const blob = await response.blob();
        const coverted = await convertHeicToJpg(blob);
        imageUrlFinal = await convertBlobToBase64(coverted.resultBlob) as string;
      }
      const base64Image = await resizeImageMax2k(imageUrlFinal) as any
      setImage(base64Image)
      setIsOpen(true);
      dispatch(setLoadingApp.setLoading(false))
    };
    reader.readAsDataURL(files[0]);
    e.target.value = null
  };

  const getCropData = async (image: string, width: number, height: number) => {
    setCropData(image);
    setIsOpen(false);
  };

  const onToRemove = () => {
    setCropData('')
  }

  const onHandleSwapface = async () => {
    try {
      if (cropData) {
        if (userInfo.coins < dataCost.swapFace) {
          NotifyController.warning(t('Not_enough_credit'))
          props.setOpenPaymentModal(true);
          setSwapfaceModal(false);
          setCropData('');
          return;
        }
        if (props.listGenerating.length === userInfo.maxConcurrent) {
          swal(t('queue_full'), '', 'warning')
          return;
        }
        tempDisableButton();
        const body = {
          originImageUrl: selectedImage,
          initImage: cropData,
          id: imageSFParams.id
        }
        const generatingImage = [
          {
            specialId: generateRandomNumberString(10),
            negativePrompt: '',
            prompt: '',
            percent: 0,
            text: 'Generating...',
          },
          ...props.listGenerating
        ];
        props.setListGenerating(generatingImage);
        await FashionService.getInstance().processSwapface(body);
        dispatch(userSaga.setCredits(currentCoins - dataCost.swapFace));
        const element = document.getElementById('top-list');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        props.setIsGenerating(true);
      } else NotifyController.warning(t('Please_input_your_image'))
    } catch (error: any) {
      setDisableGenerateButton(false);
      swal(t(error.message), '', 'error')
      logEvent(analytics, `server_error_${customizeKey(error.message)}`)
    }
  }

  const openTool = async (image: any) => {
    setPreviewModal(false)
    const url = image.url_v2[currentIndex]
    const base64 = await urlToBase64(url + '?not-from-cache-please')
    dispatch(toolEditorImage.setImageInput('data:image/jpeg;base64,' + base64))
    dispatch(toolEditorImage.setSizeImage({
      width: image.width,
      height: image.height
    }))
    dispatch(toolEditorImage.setTypeEdit(TypeEditor.POST))
    dispatch(toolEditorImage.setSamID(image.samSpecialId))
    const typeOutputImage = image.type;
    dispatch(toolEditorImage.setTypeOutputOfPost(typeOutputImage))
    dispatch(toolEditorImage.setImageId(image.id))
    props.setOpenModalTool(true)
    // navigate(ROUTERS.TOOL_EDITOR)
  }

  const LoadingImage = (percent: number = 0, urlImg: string = '#', status: any, listDataImagePreview: any = ['']) => {
    if (status === 0) status = 'IN_QUEUE'
    return (
      <div >
        {status !== "FINISHED" ?
          <div className='flex flex-col gap-2 justify-center items-center border-[#F5F5F5] border border-solid rounded-lg w-[150px] h-[150px]'
            style={{
              backgroundColor: percent > 0 ? '#F5F5F5' : 'white'
            }}>
            <div>
              <div className="container-loading-progress"></div>
            </div>
            <div className='text-[13px] px-2 text-center whitespace-nowrap'>
              {(status === "IN_QUEUE" || typeof status === 'number') && t('Wait in line')}
              {status === "PROCESSING" && t('In_progress') + percent + '%'}
            </div>
            <div className='w-[130px]'>
              <Progress strokeColor={'#0047FF'} trailColor={'#B5C5FD'} status='active' percent={percent} showInfo={false} />
            </div>
          </div> :
          <div className='img-item mr-3 cursor-pointer'>
            <img
              className="item-img-render box-item-img"
              width={150}
              height={150}
              src={urlImg}
              alt=""
              onContextMenu={(e) => {
                e.preventDefault();
              }}
              onClick={() => handleImageClick(listDataImagePreview[0], urlImg, listDataImagePreview[1], listDataImagePreview[2], true)}
            />
          </div>
        }

      </div>
    )
  }

  const renderImageComparison = () => {
    if (selectedImage && selectedOriginImage) {
      return <div className='min-w-[512px] min-h-[512px] sm:min-w-[300px] sm:min-h-[300px]'>
        <ReactCompareImage leftImage={selectedOriginImage} rightImage={selectedImage} />
      </div>
    } else {
      return <img className='min-w-[512px] min-h-[512px] sm:min-w-[300px] sm:min-h-[300px]' src={selectedImage} alt="" />;
    }
  };

  const onCancelJob = async (id: string) => {
    swal({
      title: t('be_careful'),
      text: t('want_to_delete'),
      icon: 'warning',
      buttons: {
        cancel: {
          text: t('cancel'),
          value: null,
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: t('delete'),
          value: true,
          visible: true,
          closeModal: true,
        },
      },
    }).then(async (result) => {
      if (result) {
        await FashionService.getInstance().cancelJobGen(id);
        swal(t('delete_successfully'), '', 'success');
        logEvent(analytics, 'delete_output')
      }
    });
  }
  useEffect(() => {
    if (isGenerating || isGoHome) {
      setPreviewModal(false)
    }
  }, [isGenerating, isGoHome])
  const boxRight = document.getElementById('box-right');


  useEffect(() => {
    if (boxRight) {
      const maxScrollTopLeft = boxRight.clientHeight;
      const handleBoxScroll = (event: Event) => {
        const target = event.target as HTMLElement;
        if (target.scrollTop >= 350) {
          dispatch(setIsDisplayHeader.setIsDisplayHeader(true))

        } else {
          dispatch(setIsDisplayHeader.setIsDisplayHeader(true))

        }
        if (target.scrollTop <= maxScrollTopLeft) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      };

      if (boxRight) {
        boxRight.addEventListener('scroll', handleBoxScroll);
        // Cleanup function to remove the event listener when component unmounts
        return () => {
          boxRight.removeEventListener('scroll', handleBoxScroll);
        };
      }
    }
  }, [boxRight]);
  return (
    <div className="list-img-render flex flex-col gap-4 p-8 " id={'box-right'} style={{
      overflowY: 'scroll',
      overflowX: 'hidden',
      height: 'calc(85vh - 70px)',
      backgroundColor: '#101828',
      borderRadius: '16px',
    }}>
      {isShowFormWhiteList && <PopupAddToWhiteList isShowFormWhiteList={isShowFormWhiteList} setIsShowFormWhiteList={setIsShowFormWhiteList} setPermission={typePermission} />}
      <div className='flex justify-between'>
        <span ref={props.resultRef} className="text-white  text-xl font-bold" id="top-list">{t('Generating_result')}</span>
      </div>
      {(props?.listImages?.length <= 0 && props?.listGenerating?.length <= 0) && <div className='text-white text-center'>
        <Empty description={
          <span className='text-white'>
            {t('empty_result')}
          </span>
        } /></div>}
      {props.pagination && props.pagination.pageSize &&
        <div className='flex justify-center'>
          {props.pagination.totalImages >= props.pagination.pageSize && <Pagination
            className="custom-pagination mt-3"
            defaultCurrent={props.pagination.currentPage}
            total={props.pagination.totalImages}
            onChange={props.handlePageChange}
            pageSize={props.pagination.pageSize}
            current={props.pagination.currentPage}
            showSizeChanger={false}
            responsive
          />}

        </div>
      }
      {props.listGenerating.length > 0 && props.listGenerating.map((item: IImageGen, index: number) => {
        const arrayStatus = item.numberOfImages || item?.statuses;
        const arrayPercents = item?.percents || [item?.percent];
        const arrayUrl_v2 = item?.url_v2 || [];
        return (
          <div key={index} className="item-render item-image-render w-full " style={{
            borderBottom: '1px gray solid'
          }}>
            <div className="flex flex-row justify-between pe-4">
              <div className="time  text-sm font-semibold leading-5">{moment.tz(item.createdAt, timezone).locale(i18n.language).format("YYYY-MM-DD HH:mm:ss")}</div>
              <Tooltip placement="bottom" title={t('cancel')} >
                <img src={trash} className={`cursor-pointer text-lg `} onClick={e => onCancelJob(item.specialId)} />
              </Tooltip>
            </div>
            <div>
              <div className='flex  gap-2 overflow-x-auto h-[150px] max-h-[165px] p-2' style={{
                overflowX: 'auto',
                overflowY: 'hidden',
                height: 'auto'
              }}>
                {
                  arrayStatus?.map((e: any, index: number) => {
                    const listDataImagePreview = [item?.url_v2, image, item.origin_image_url]
                    return LoadingImage(arrayPercents[index], arrayUrl_v2[index], e, listDataImagePreview)
                  })
                }
              </div>
            </div>
            {isAdmin && <CollapseCustomize promptInput={item.prompt} negativeInput={item.negativePrompt} id={item?.id ? item?.id : ''} />}
          </div>
        )
      })}
      {
        props?.listImages?.map((image: IImage, index: number) => (
          <div key={index} className="item-render   item-image-render w-full ">
            <div className="flex flex-row justify-between pr-4">
              <div className="time  text-sm font-semibold leading-5">{moment.tz(image.created_ts, timezone).locale(i18n.language).format("YYYY-MM-DD HH:mm:ss")}</div>
              <div className="btn-right flex gap-4 ">
                {
                  image.type !== FashionToolType.UPSCALE
                  && image.type !== FashionToolType.SWAPFACE
                  && image.type !== FashionToolType.REMOVE_OBJECT
                  && image.type !== FashionToolType.BORDER_SMOOTH
                  && image.type !== FashionToolType.REMOVE_REDUNDANT_DETAIL
                  && image.type !== FashionToolType.CORRECT_PART
                  && image.type !== FashionToolType.TRY_OUTFIT
                  && (
                    <Tooltip placement="bottom" title={t('tooltip_copy')}>
                      <img src={refresh} className="cursor-pointer text-lg" onClick={() => onCopySetting(image)} onContextMenu={(e) => {
                        e.preventDefault();
                      }} />
                    </Tooltip>
                  )}
                <Tooltip placement="bottom" title={t('delete')} >
                  <img src={trash} className="cursor-pointer text-lg" onClick={() => onDelete(image.id)} />
                </Tooltip>
                {image.type !== FashionToolType.TRY_OUTFIT && <Tooltip placement="bottom" title={'Copy Link'}>
                  <CopyOutlined className="cursor-pointer text-lg" onClick={() => onCopyLink(image.id, "link")} />
                </Tooltip>}
              </div>
            </div>
            <div className="container-tag flex gap-2 mt-2 mb-1">
              <BoxTagName image={image} />
            </div>
            {image.type !== FashionToolType.UPSCALE && image.type !== FashionToolType.SWAPFACE && isAdmin && (
              <CollapseCustomize
                promptInput={image.outputData?.prompt}
                negativeInput={image.outputData?.negative_prompt}
                user={props.user}
                response={image.response}
                genTime={millisecondsToMinutesAndSeconds(image.finished_ts - image.created_ts)}
                genTimePerJob={genTimePerJob(image.gen_time_per_job)}
                queueTimePerJob={genTimePerJob(image.queue_time_per_job)}
                id={image?.id ? image?.id : ''}
              />
            )}
            <div className="flex gap-4" style={{
              height: '100%',
              overflowX: 'auto',
              overflowY: 'hidden'
            }}>
              <div className='flex overflow-x-auto max-h-[165px]'>
                {image?.url_without_watermark && image?.url_without_watermark.length > 0 ? image.url_without_watermark.map((imageUrl, index) => (
                  <div className="img-item mr-3 cursor-pointer" key={index} id={`img-list-${index}`}>
                    <img
                      className="item-img-render box-item-img"
                      width={150}
                      height={150}
                      src={imageUrl}
                      alt=""
                      onContextMenu={(e) => {
                        e.preventDefault();
                      }}
                      onClick={() => handleImageClick(image?.url_without_watermark, imageUrl, image, image.originImageUrl, false)}
                    />
                  </div>
                )) : <>
                  {image.url_v2.map((imageUrl, index) => (
                    <div className="img-item mr-3 cursor-pointer" key={index} id={`img-list-${index}`}>
                      <img
                        className="item-img-render box-item-img"
                        width={150}
                        height={150}
                        src={imageUrl}
                        alt=""
                        onContextMenu={(e) => {
                          e.preventDefault();
                        }}
                        onClick={() => handleImageClick(image.url_v2, imageUrl, image, image.originImageUrl, false)}
                      />
                    </div>
                  ))}
                </>}
              </div>
            </div>
          </div>
        ))
      }
      {props.pagination && props.pagination.pageSize &&
        <div className='flex justify-center'>
          {props.pagination.totalImages >= props.pagination.pageSize && <Pagination
            className="custom-pagination mt-3"
            defaultCurrent={props.pagination.currentPage}
            total={props.pagination.totalImages}
            onChange={props.handlePageChange}
            pageSize={props.pagination.pageSize}
            current={props.pagination.currentPage}
            showSizeChanger={false}
            responsive
          />}
        </div>
      }
      {isPreviewModalOpen && <Modal
        centered={true}
        zIndex={1}
        open={isPreviewModalOpen}
        onCancel={handleCloseModal}
        width={'fit-content'}
        title={
          <div className='flex gap-2 items-center justify-center'>
            {listImage.length == 1 && 'Preview'}
            <div className='flex gap-3 items-center'>
              {listImage.length > 1 && <Button type="text" disabled={currentIndex === 0} onClick={previous} >
                <img src={backArrow} alt="" />
              </Button>}
              {listImage.length > 1 && listImage?.map((val: any, index: number) => (
                <div key={index} className={`dot ${currentIndex == index ? 'active-dot' : ''}`}></div>
              )
              )}
              {listImage.length > 1 && <Button type="text" disabled={currentIndex === listImage.length - 1} onClick={next} >
                <img src={nextArrow} alt="" />
              </Button>}
            </div>
          </div>
        }
        footer={
          <div className={generating ? 'hidden' : 'flex gap-2 items-center justify-center'}>
            <div className='w-full flex gap-2'>
              {listDataPreview[0].type !== FashionToolType.UPSCALE && <Button className='w-full rounded-lg' type='primary' onClick={() => openTool(listDataPreview[0])}>{t('edit_image')}</Button>}
              <Button className='w-full rounded-lg' type='default' icon={<VerticalAlignBottomOutlined />} onClick={() => handleDownload(listImage[currentIndex])}>  {t('download')}</Button>
            </div>
          </div>
        }
      >
        <div style={{
          position: 'absolute',
          right: '35px',
          zIndex: 99,
          bottom: '33px',
          color: 'white'
        }}>
          <div>
          </div>
        </div>
        {renderImageComparison()}

      </Modal>}
      {isUpscaleModalOpen && <Modal
        centered={true}
        footer={null}
        open={isUpscaleModalOpen}
        closeIcon={customCloseIcon}
        onCancel={handleCancel}
      >
        <div className='modal-section'>
          <img src={selectedImage} alt='upscale-img' />
          <div className='flex mt-5 mb-4 justify-between'>
            <div className='flex flex-row'>
              <img className='mr-2' src={logoModeli} />
              <p>Modeli Upscaling</p>
            </div>
            <p>{t('Original_size')} ({width}x{height})</p>
          </div>
          <div className='upscale-section'>
            <span className='upscale-text mr-2'>{t('Upscale_to')}</span>
            <Select
              className='mr-2'
              defaultValue={upscaleValue}
              style={{ width: 207 }}
              onChange={handleChange}
              options={[
                { value: 1.5, label: 'x1.5' },
                { value: 2, label: 'x2' },
                { value: 3, label: 'x3' },
                { value: 4, label: 'x4', disabled: true },
              ]}
            />
            <Button onClick={handleOk} type='primary'>
              {disableGenerateButton
                ? <Spin className='pl-5 pr-5 cursor-not-allowed' indicator={antIcon} />
                : <div><span>{t('OK')}</span><span> (-{dataCost.upscale}</span><ThunderboltOutlined style={{ fontSize: 12 }} /><span>)</span></div>
              }
            </Button>
          </div>
          <div className='mt-3'>
            <p>{t('New_size')} ({width * upscaleValue}x{height * upscaleValue})</p>
          </div>
        </div>
      </Modal>}
      {isSwapfaceModalOpen && <Modal
        centered={true}
        footer={null}
        width={'fit-content'}
        title='Swap face'
        open={isSwapfaceModalOpen}
        // closeIcon={customCloseIcon}
        onCancel={handleCancel}
      >
        <div className='grid grid-cols-2 gap-3 sm:grid-cols-1 md:grid-cols-1'>
          <div className='flex justify-center'>
            <img style={{ borderRadius: '14px' }} src={selectedImage} alt='upscale-img' width={370} height={'auto'} />
          </div>
          <div className='upload flex flex-col justify-center items-center mt-8 '>
            {!cropData ?
              <>
                <div onClick={onClickUploadImage} className='icon-upload cursor-pointer flex flex-col justify-center items-center w-full h-[150px] rounded-lg box-upload hover:bg-gray-300'>
                  <input id="input-upload-image-swapface" type="file" accept="image/*" onChange={handleImageUpload} className="hidden" />
                  <img src={iconUpload} alt="icon-upload" />
                  <span className='text-gray text-sm text-upload-guide'>{t('Only_support_image_types_png/jpg/jpeg')}</span>
                  <span className='text-gray'>{t('upload_your_template')}</span>
                </div >
                {/* {isOpen && <ModalResize
                  openModal={() => setIsOpen(true)}
                  isOpen={isOpen}
                  image={image}
                  getCropData={getCropData}
                  closeModel={() => setIsOpen(false)}
                  setImage={setImage}
                />} */}
                {isOpen && <ModalEditor
                  openModal={() => setIsOpen(true)}
                  isOpen={isOpen}
                  image={image}
                  getCropData={getCropData}
                  closeModel={() => setIsOpen(false)}
                  setImage={setImage}
                />}
              </>
              :
              <Tooltip title={t("click_to_remove")} color={'red'} key={'red'} className='cursor-pointer'>
                <div onClick={onToRemove} className='icon-upload img-preview  relative  flex flex-col justify-center items-center  rounded-lg  hover:bg-gray-300'>
                  <img src={cropData} id="img-upload" className='rounded-lg' alt="preview-image" width={'370'} height={'auto'} />
                  <DeleteOutlined className=' btn-delete-image absolute  text-gray rounded ' style={{
                    fontSize: '10px'
                  }} />
                </div>
              </Tooltip>

            }
            <div className='mt-4'>
              <Button onClick={onHandleSwapface} type='primary'>
                {disableGenerateButton
                  ? <Spin className='pl-5 pr-5 cursor-not-allowed' indicator={antIcon} />
                  : <div className='flex'><p><span>{t('Swap_face')}</span><span> (-{dataCost.swapFace}</span><ThunderboltOutlined style={{ fontSize: 12 }} /><span>)</span></p></div>
                }
              </Button>
            </div>
          </div >
        </div>
      </Modal>}
      {props.openModalTool && <ModalTool isOpenModal={props.openModalTool} setIsOpenModal={() => props.setOpenModalTool(false)} setIsGenerating={props.setIsGenerating} />}
    </div >
  )
}
export default ListRenderedImages
